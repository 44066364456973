.content-design .template-header{
    /*box-shadow: 2000px 2000px 2000px 2000px rgba(0, 0, 0, 0.5);*/
}

.content-design .header-text .btn-campaign-edit{
    top: -16px;
    right: -16px;
}
/* Leaderboard Skeleton */
.leaderboard-skeleton .template-skeleton .add-icon-logo,
.generic-skeleton .add-icon-logo,
.uploader-skeleton .add-icon-logo {
    position: absolute;
    top: -12px;
    right: -9px;
}
.leaderboard-skeleton .add-icon-reward-wrapper,
.generic-skeleton .add-icon-reward-wrapper,
.youtube-skeleton .add-icon-reward-wrapper,
.music-skeleton .add-icon-reward-wrapper,
.hashtag-skeleton .add-icon-reward-wrapper,
.uploader-skeleton .add-icon-reward-wrapper {
    position: absolute;
    top: -16px;
    right: 0;
    display: flex;
    align-items: center;
}
.leaderboard-skeleton .reward_edit_icon__bg,
.leaderboard-skeleton .add-icon-reward,
.generic-skeleton .reward_edit_icon__bg,
.generic-skeleton .add-icon-reward {
    cursor: pointer;
    display: inline-block;
    float: none;
    top: 0;
    right: 0;
    background: white;
}
.leaderboard-skeleton .add-icon-reward-img {
    position: absolute;
    top: -14px;
    right: -8px;
     float: none;
    font-size: 20px;
}
.leaderboard-skeleton .reward-wrap{
    padding: 3rem;
}
/* Generic Skeleton */
.generic-skeleton .equal-height-JlocK{
    height: auto !important;
    overflow: initial;
}
/* Youtube Skeleton */
.youtube-skeleton .navbar-collapse {
    flex-grow: inherit;
}
.youtube-skeleton .navbar-collapse {
    position: absolute;
    right: 0;
}
/* Hashtag Skeleton */

/* File Uploader Skeleton */
.uploader-skeleton .content_bg_position {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.uploader-skeleton .template-footer {
    background: transparent;
}
.uploader-skeleton .border-radius-0 {
    border-radius: 0 !important;
}
.uploader-skeleton .reward-wrap{
    padding: 1rem;
}
/* Event Skeleton */
.event-skeleton .template-header {
    min-height: 60vh;
}
.event-skeleton .template-skeleton .header-text {
    min-width: 40vw;
}
.event-skeleton .template-header .logo-center {
    padding: 5px 15px;
    text-align: center;
    text-transform: uppercase;
}