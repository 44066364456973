/* Template css
    1- Generic template
    2- Leaderboard template
    3- Youtube template
    4- Music template
    5- hashtag template
*/
/* Generic CSS */
/* The laptop with borders */
.desktop {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
    transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
    left: 50%;
    position: absolute;
    width: 1366px;
    /*height: 800px;*/
    border-radius: 6px;
    border-style: solid;
    border-color: black;
    border-width: 24px 24px 80px;
    background-color: black;

}

/* The keyboard of the laptop */
.desktop:after {
    content: '';
    display: block;
    position: absolute;
    width: 1600px;
    height: 60px;
    margin: 80px 0 0 -110px;
    background: black;
    border-radius: 6px;
}

/* The top of the keyboard */
.desktop:before {
    content: '';
    display: block;
    position: absolute;
    width: 250px;
    height: 30px;
    bottom: -110px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    background: #f1f1f1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
}

/* The screen (or content) of the device */
.desktop .content {
    /*width: 1366px;*/
    height: 100vh;
    /*overflow: hidden;*/
    /*border: none;*/
}
/* The device with borders */
.smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
    /*width: 360px;*/
    height: 100%;
    /*background: white;*/
}
.smartphone .content-design {
    overflow-y: auto;
    height: 520px;
}
/* The device with borders */
.tablet-panel {
    position: relative;
    width: 768px;
    /*height: 1024px;*/
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet-panel:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet-panel:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet-panel .content {
    /*width: 768px;*/
    height: 100vh;
    /*background: white;
    margin: -1px;*/
}
.tablet-panel .content-design {
    overflow-y: auto;
    /*height: 905px;*/
}
/* Generic Template */
.generic-template .reward-wrap:nth-last-child(2) {
    /*padding: 45px 30px;*/
    margin-bottom: 0 !important;
    /*margin-top: 40px;*/
}

/* Leaderboard Template*/
.leaderboard-template .template-header{
    padding: 7rem 0;
}
.leaderboard-template .header-text h2{
    margin: 0;
}
.leaderboard-template .reward-wrap {
     padding: 0;
     margin: 0;
}
.leaderboard-template .leaderboard_list_box {
     border: none;
}
.leaderboard-template .leaderboard_list_items{
    border: none;
}
.leaderboard-template .leaderboard_list_box {
    top: -162px;
}
.leaderboard-template .leaderboard_signup {
    border-radius: 27px;
    padding: 8px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
.leaderboard-template .leaderboard_signin{
    font-size: inherit;
}
.leaderboard-template .user-Messages-card .u-img .profile-img {
    width: 42px;
    height: 42px;
}
.leaderboard-template .reward-wrap {
    padding: 3rem;
}
/* Youtube Template */
.youtube-template .navbar-collapse {
    position: absolute;
    right: 0;
}
.youtube-template .youtube-frame{
    width: 100%;
    height: 620px;
}
.youtube-template .reward-img{
    max-width: 250px;
    max-height: 250px;
}

/* Music template */
.music-template .border-radius-0,
.uploader-template .border-radius-0,
.hashtag-template .border-radius-0 {
    border-radius: 0 !important;
    border: none;
}
.music-player-safari {
    width: 250px;
    height: 31px;
}
.music-player-chrome {
    width: 300px;
    height: 54px;
}
.music-player-firefox {
    width: 300px;
    height: 40px;
}
/* File Uploader Template */
.uploader-template .content_bg_position {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.progress_bar {
    width: 370px;
    height: 147px;
    background: rgba(1, 1, 1, 0.68);
    border-radius: 15px;
    margin: 12px auto 0;
}

.progress_bar_header p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 15px;
}

.progress_bar_header p span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    float: right;
}

.progress_bar_content .progress {
    height: 2rem;
    /*margin-bottom: 10px !important;*/
}
.uploader-template .reward-wrap {
    padding: 1rem !important;
}
/*Upload file*/
.uploader-template .template-footer{
    background: none;
}
.uploader-template .mobile-uploader{
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.uploader-template .upload-icon{
    max-width: 50px;
}
.uploader-template .template-header, .uploader-template .upload-main-section, .uploader-template .template-footer{
    max-width: 650px;
    margin-left: auto;
    margin-right: 50px;
}

/* Hashtag template */
.hashtag-template .trending_image_box .thumb_wrap {
    padding: 0 4px;
}
.hashtag-template .hashtag-text {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    background: rgba(255, 255, 255, 0.58);
    padding: 0px 30px;
}

/* Event Template */
.event-template .template-header{
    padding-bottom: 0;
    min-height: 100vh;
}
.event-template .header-wrapper {
    height: calc(100% - 95px);
}


@media (min-width: 1600px){
    .leaderboard-template .container, .generic-template .container, .youtube-template .container, .uploader-template .container {
         max-width: 1340px;
    }
}
@media (min-width: 1199px){
    .w-xl-75{
        max-width: 75%;
    }
}
@media (min-width: 767px){
    .youtube-template .navbar-collapse,
    .hashtag-template .navbar-collapse,
    .generic-template .navbar-collapse,
    .leaderboard-template .navbar-collapse,
    .music-template .navbar-collapse,
    .uploader-template .navbar-collapse,
    .event-template .navbar-collapse {
        border: none !important;
        background:none !important;
    }
}



@media only screen and (max-width: 992px) {
    .leaderboard-template .container,
    .generic-template .container,
    .youtube-template .container,
    .music-template .container,
    .hashtag-template .container,
    .uploader-template .container,
    .event-template .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .generic-template .reward-wrap img{
        /* max-width: 400px;
        max-height: 400px;
        width: 100%; */
    }
    .youtube-template .youtube-wrapper{
        width: 100%;
        padding: 0;
    }
}
/*// Large devices (desktops, 1400 and up)*/
@media (max-width: 991px) {
    .leaderboard-template .leaderboard_list_box {
        position: initial;
        top: 0;
    }
    .leaderboard-template .template-header .logo img,
    .youtube-template .template-header .logo img,
    .music-template .template-header .logo img,
    .hashtag-template .template-header .logo img,
    .uploader-template .template-header .logo img,
    .generic-template .template-header .logo img,
    .event-template .template-header .logo-center img {
        max-width: 120px;
        max-height: 120px;
    }
    .hashtag-template .trending_image_box {
        width: 286px;
        height: auto;
        background: #FFFFFF;
    }
    .hash_tag_box {
        margin-top: 30px;
    }
    .hashtag-template .hashtag-text {
        background: none;
        font-size: 40px;
    }
    .template-content-area .reward-text {
        text-align: center !important;
    }
}
@media (max-width: 767px) {
    .leaderboard-template .template-header {
        padding: 4rem 0;
    }
    .generic-template .reward-wrap img{
        /* max-width: 320px;
        max-height: 320px;
        width: 100%; */
    }
    .youtube-template .youtube-frame{
        width: 100%;
        height: 350px;
    }
    .youtube-template .template-header .logo img {
        max-width: 120px;
        max-height: 120px;
    }
    .hashtag-template .trending_image_box {
        width: 286px;
        height: auto;
        background: #FFFFFF;
    }
    .uploader-template .template-header, .uploader-template .upload-main-section, .uploader-template .template-footer{
        margin-left: auto;
        margin-right: auto;
    }
    .youtube-template .navbar-collapse,
    .hashtag-template .navbar-collapse,
    .generic-template .navbar-collapse,
    .leaderboard-template .navbar-collapse,
    .music-template .navbar-collapse,
    .uploader-template .navbar-collapse,
    .event-template .navbar-collapse {
        /*position: relative;*/
        position: absolute;
        right: 0;
        width: 100%;
        top: 125px;
        border: 1px solid;
        padding: 10px;
        border-radius: 5px;
        z-index: 1;
        transition: 2s ease-in-out
        /*transition-timing-function: ease-in-out;*/
    }
}
@media (max-width: 768px) {
    .header-text * {
        font-size: 4vw !important;
        word-break: normal;
        text-align: center;
    }
    .header-text .sub-heading-mob {
        font-size: 16px !important;
    }
    .reward-ht * {
        font-size: 30px !important;
    }
    .reward-dest * {
        /*font-size: 18px !important;*/
    }
    .reward-social {
        /* display: grid !important; */
        justify-content: center;
        text-align: center;
    }
    .reward-social .nav {
        display: inline-flex;
    }
    .signup-bm {
        display: flex;
        justify-content: center;
        border: none;
    }
    .signup-bm btn {
        border: none !important; ;
    }
    /*.navbar-collapse {
        background-color: rgba(255,255,255, 0.49) !important;
    }*/
}
@media (max-width: 576px) {
    .leaderboard-template .template-header .logo img,
    .youtube-template .template-header .logo img,
    .music-template .template-header .logo img,
    .hashtag-template .template-header .logo img,
    .uploader-template .template-header .logo img,
    .generic-template .template-header .logo img,
    .event-template .template-header .logo-center img {
        max-width: 120px;
        max-height: 120px;
    }
    .generic-template .reward-wrap img{
        /* max-width: 280px;
        max-height: 280px;
        width: 100%; */
    }
    .youtube-template .youtube-frame{
        width: 100%;
        height: 270px;
    }
    .hashtag-template .trending_image_box .thumb_wrap {
        padding: 0;
    }
    .reward-social h3 {
        font-size: 14px;
    }
    .Demo__some-network svg {
        width: 25px;
        height: 25px;
    }
    .copy-link svg {
        width: 20px;
        height: 14px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .copy-link button {
        width: 20px;
        height: 20px;
        min-width: 15px;
    }
    .copy-link {
        padding: 0.1rem;
    }
    .progress_bar {
        width: 250px;
    }
}
