/*iframe {
    display: none;
}*/
/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://db.onlinewebfonts.com/c/409f84f7dbf84b0d4b3abfe2cb39dadb?family=Alba');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

:root {
    --neutralNavy: #024799;
    --turquoise: #02CEB0;
    --burntOrange: #D36011;
    --darkOrchid: #8F41C2;
    --majorelleBlue: #5E48E3;
    --deleteColor: #EA4848;
    --blueJeans: #48A9F8;
    --royalBlueDark: #23225C;
    --greenPantone: #3CA632;
    --emerald: #1BCF85;
    --cadmiumOrange: #F18524;
    --goldMetallic: #E0BA39;
    --dashBoardBg: #ECF0F5;
    --navInactive: #535763;
    --atomicTangerine: #FF965D;
    --silverGrey: #DBDAD9;
}

/*html { font-size: 62.5%; }*/
body {
    font-family: 'Urbanist', sans-serif;
    background: none;
    font-size: 14px;
}

.heading_font {
    font-family: 'Audiowide';
}

.body_font {
    font-family: 'Urbanist', sans-serif;
}

-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    /* or add it to the track */
}

/*.btn{
    font-size: 16px;
    border-radius: 40px;
    padding: 10px 30px;
}*/
.btn-burnt {
    color: white;
    background-color: var(--burntOrange);
    border-color: var(--burntOrange);
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 19px;
}

.btn-burnt-sm {
    color: white;
    background-color: var(--burntOrange);
    border-color: var(--burntOrange);
}

.btn-neutral-navy {
    color: white;
    background-color: var(--neutralNavy);
    border-color: var(--neutralNavy);
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
}

.text-neutral-navy {
    color: #024799;
}

.burnt {
    background: #D36011;
}

.bg-c-burnt {
    background: #D36011;
}

.burnt-text {
    color: #D36011;
}

.cadmium-text {
    color: #F18524;
}

#main-logo {
    max-width: 140px;
}

.pcoded-header .m-header {
    justify-content: end;
    padding: 0 13px;
}

.pcoded-navbar.menu-light {
    background-color: #fff;
    color: #535763;
    box-shadow: 4px 2px 10px rgba(0 0 0, 0.12);
    border-radius: 0px 15px 0px 0px;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar>li>a,
.pcoded-navbar.menu-light .pcoded-inner-navbar .pcoded-submenu li>a {
    color: var(--royalBlueDark);
}

.pcoded-navbar.menu-light .pcoded-inner-navbar>li>a.active {
    background: var(--royalBlueDark);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 22px;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar .pcoded-submenu li>a.active {
    color: var(--majorelleBlue);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /*line-height: 17px;*/
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption>label {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: var(--royalBlueDark);
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: var(--blueJeans);
    border-color: var(--blueJeans);
}

.cursor-pointer {
    cursor: pointer;
}

.pcoded-content {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
}

.required {
    color: var(--burntOrange);
}

.form-group .form-control {
    padding-bottom: 0;
}

/*================= Text Color ===============*/
.text_royal_blue_dark {
    color: var(--royalBlueDark);
}

.blue-jeans-text {
    color: var(--blueJeans) !important;
}

.emerald-text {
    color: var(--emerald) !important;
}

.atomic-tangerine-text {
    color: var(--atomicTangerine) !important;
}

.cadmium-orange-text {
    color: var(--cadmiumOrange) !important;
}

.dark-orchid-text {
    color: var(--darkOrchid) !important;
}

.gold-metallic-text {
    color: var(--goldMetallic) !important;
}

.green-pantone-text {
    color: var(--greenPantone) !important;
}

/*================= Bg Color ===============*/
.blue-jeans-bg {
    background: var(--blueJeans) !important;
}

.emerald-bg {
    background: var(--emerald) !important;
}

.atomic-tangerine-bg {
    background: var(--atomicTangerine) !important;
}

.cadmium-orange-bg {
    background: var(--cadmiumOrange) !important;
}

.dark-orchid-bg {
    background: var(--darkOrchid) !important;
}

.gold-metallic-bg {
    background: var(--goldMetallic) !important;
}

.green-pantone-bg {
    background: var(--greenPantone) !important;
}

/*================= Buttons ===============*/
.custom_btn_add {
    /* position: absolute;
    right: 23px;
    top: 29px; */
    background: var(--cadmiumOrange);
    border: 1px solid var(--cadmiumOrange);
    border-radius: 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 8px 26px;
}

.btn-delete {
    background: #D31111;
    color: #FFFFFF;
    border-radius: 5px;
}

.btn-neutral-navy {
    background: #024799;
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 19px;
}

.swal2-styled.swal2-confirm {
    background: var(--cadmiumOrange) !important;
    color: #fff;
    padding: 5px 40px;
    border: 1px solid var(--cadmiumOrange);
    border-radius: 15px !important;
}

.swal2-styled.swal2-cancel {
    background: var(--royalBlueDark) !important;
    color: #fff;
    padding: 5px 25px;
    border: 1px solid var(--royalBlueDark);
    border-radius: 15px !important;
}

.btn-done {
    background: var(--navInactive);
    border: 1px solid var(--navInactive);
    border-radius: 19px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 30px;
}

/*================= Icons ===============*/
.pen_icon {
    background: var(--blueJeans);
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    border: 1px solid #FFFFFF;
}

/*================= Generic Classes ===============*/
.custom_card_heading {
    /* position: absolute;
    top: 24px; */
    color: var(--royalBlueDark);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 47px;
    text-transform: capitalize;
}

.reward_bg_position {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.btn-secondary {
    border: none;
}

.responsive-buttons {
    padding: 0 10px !important;
    color: var(--cadmiumOrange) !important;
    font-size: 24px;
}

.tablet-view {
    max-width: 765px;
}

.mobile-view {
    max-width: 420px;
}

.active_icon {
    color: var(--royalBlueDark) !important;
}

.notification-badge {
    position: absolute !important;
    top: 8px !important;
    right: -1px;
    background: red;
    border-radius: 50px;
    width: 10px;
    height: 10px;
    font-size: 5px;
}

.notification-read {
    position: absolute !important;
    right: 10px;
    background: red;
    border-radius: 50px;
    width: 5px;
    height: 5px;
    font-size: 2px;
}

/*================= 01 - Auth ===============*/
.auth-wrapper {
    background: #E5E5E5;
}

.bg-position {
    background-image: url("../../assets/images/signup_bg.jpg") !important;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 100%;
    position: absolute;
    width: 50vw;
    left: 0;
    top: 0;
    /*background: radial-gradient(50% 50% at 50% 50%, #48A9F8 0%, #23225C 100%);*/
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background: rgb(72, 169, 248);
    background: -moz-radial-gradient(circle, rgba(72, 169, 248, 1) 0%, rgba(35, 34, 92, 1) 100%);
    background: -webkit-radial-gradient(circle, rgba(72, 169, 248, 1) 0%, rgba(35, 34, 92, 1) 100%);
    background: radial-gradient(circle, rgba(72, 169, 248, 1) 0%, rgba(35, 34, 92, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#48a9f8", endColorstr="#23225c", GradientType=1);
}

.auth-content label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--royalBlueDark);
}

.auth-content h2 {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: var(--royalBlueDark);
}

.auth-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.save_credentials label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #535763;
    padding-top: 2px;
}

.auth-content .form-group input {
    background: #FFFFFF;
    padding: 10px;
    border: 1px solid var(--blueJeans);
    border-radius: 70px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.auth-content .form-group input::placeholder {
    color: #DBDAD9;
}

.auth-content button {
    background: var(--royalBlueDark);
    border-radius: 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.auth-content button:hover {
    background: #151437;
    color: #FFFFFF;
}

.forgot_pass p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.forgot_pass a {
    color: var(--blueJeans) !important;
}

.back_to_login {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px;
    color: var(--blueJeans) !important;
}

/* new css end */
/*.auth-logo{
    max-width: 130px;
}*/
.auth-wrapper .auth-side-img {
    padding-right: 50vw;
}

.auth-wrapper .auth-side-form {
    width: 50vw;
}

.auth-wrapper .auth-content:not(.container) {
    width: 400px;
}

.auth-wrapper .auth-side-form {
    font-size: 16px;
    /*background-image: url("../../assets/images/signup-bg.png") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;*/
}

.auth-side-form label {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.auth-side-form p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.auth-side-form p a {
    color: #0A66C2;
}

.auth-side-form .btn {
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.auth-side-form h2 {
    font-size: 40px;
}

.auth-side-form .btn-turquoise {
    font-size: 20px;
}

.auth-side-form p {
    font-size: 16px;
    color: black;
}

.auth-side-form .form-control:focus {
    background: none;
    border: 1px solid #02CEB0
}

.change-pass-fields input {
    border-bottom: 1px solid #000000 !important;
}

.change-pass-fields input:focus {
    border-bottom: 1px solid #D36011 !important;
    background-image: none !important;
}

.change_pass_button {
    background: #D36011;
    border-radius: 5px;
    border: 1px solid #D36011;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
}

.view-pass {
    float: right;
    margin-right: 6px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
}

/*================= 02 - Overview ===============*/
.latest--activity--card .card-header {
    padding: 15px 20px;
    background: var(--blueJeans);
    border-radius: 15px 15px 0px 0px;
}

.latest--activity--card .card-header h5 {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.overview .social-card {
    color: #fff;
    overflow: hidden;
    /*background: var(--blueJeans);*/
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.overview .social-card h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    font-family: 'Audiowide';
}

.overview .social-card p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.overview .social-card .social-icon {
    text-align: center;
    padding: 15px 18px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 15px;
    background: transparent;
}

.campaign-tile {
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 25%);
    background: rgba(35, 34, 93, 0.6);
    /*margin-top: 15px;*/
    margin-bottom: 20px;
    border-radius: 15px;
    height: 200px;
}

.campaign-tile img {
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.campaign-tile-overlay {
    position: absolute;
    background: rgba(35, 34, 93, 0.6);
    z-index: 1;
    height: 100%;
    top: 0;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: 1s;
    cursor: pointer;
}

.campaign-tile:hover .campaign-tile-overlay {
    display: flex;
    transition: 1s;
}

.campaign-tile-overlay h4 {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.btn-campaign-view {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--neutralNavy);
    background: #FFFFFF;
    border-radius: 17px;
    font-style: normal;
    padding: 4px 15px;
}

.pick-template .choose--temp--card .selection-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(2, 71, 153, 0.70);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: white;
    z-index: -1;
}

.campaign-active-status .selection-overlay {
    /*border: 5px solid var(--blueJeans);*/
    /*filter: drop-shadow(0px 0px 10px #0099FF);*/
}

.pick-template .choose--temp--card.campaign-active-status .selection-overlay {
    z-index: 1;
}

.campaign-quote p {
    line-height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: var(--royalBlueDark);
}

.campaign-quote button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF !important;
    background: var(--royalBlueDark);
    border-radius: 40px;
}

campaign-quote button:hover {
    color: #FFFFFF !important;
    background: #1c1b63;
    border: 1px solid #1c1b63;

}

/*================= 03 - Clients ===============*/
.clients-list .rdt_TableHeadRow {
    color: #FFFFFF;
    background: var(--blueJeans);
    border-radius: 15px 15px 0px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.clients-list .data-table-extensions>.data-table-extensions-filter {
    position: relative;
}

.clients-list .data-table-extensions>.data-table-extensions-filter>.filter-text {
    background: #ECF0F5;
    border: 1px solid var(--blueJeans);
    border-radius: 31px;
    padding: 10px 30px;
}

.clients-list .data-table-extensions>.data-table-extensions-filter>.filter-text:hover {
    border-bottom-color: var(--neutralNavy);
}

.clients-list .data-table-extensions>.data-table-extensions-filter>.filter-text:focus,
.data-table-extensions>.data-table-extensions-filter>.filter-text:hover {
    border-bottom-color: var(--neutralNavy);
}

.data-table-extensions>.data-table-extensions-filter>.icon {
    position: relative;
    top: 9px;
    left: 30px;
}

.clients-list .data-table-extensions {
    position: absolute;
    top: 20px;
    right: 185px;
    width: auto;
    padding: 0;
}

.clients-list .btn-edit {
    background: var(--majorelleBlue);
    color: #FFFFFF;
    border-radius: 15px;
}

.clients-list .btn-view {
    background: var(--darkOrchid);
    color: #FFFFFF;
    border-radius: 15px;
}

.clients-list .btn-view-disable {
    background: var(--darkOrchid);
    opacity: .80;
    color: #FFFFFF;
    border-radius: 15px;
    cursor: default;
}

.clients-list .btn-delete {
    background: var(--deleteColor);
    color: #FFFFFF;
    border-radius: 15px;
}

.clients-list .rdt_TableRow:nth-child(odd) {
    background: #ECF0F5;
}

.clients-list .fhCFWO:not(:last-of-type) {
    border-bottom-style: none;
}

.clients-list .rdt_TableCell {
    border-right: 1px solid #DBDAD9;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

}

.clients-list .rdt_TableCell:last-child {
    border-right: none;
}
/*================= 03.1 - Clients Profile ===============*/

.profile-cover-btn {
    /* position: relative;
    top: -60px;
    right: 30px; */
    float: right;
    background: var(--burntOrange);
    border-radius: 2px;
    padding: 8px 15px;
    color: #fff;
}

.user-card-2.shape-right .cover-img-block {
    clip-path: none;
}

.user_bg_img {
    background-image: url("../images/user/cover.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 354px;
}

.nav_position {
    color: #fff;
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.nav-tabs.nav_position .nav-link.active {
    background: none;
    border-bottom: 5px solid var(--burntOrange);
}

.profile_user_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
}

.card_body_position {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 0 !important;
}

.margin_bottom {
    margin-bottom: 40px;
}

.profile_user_name p {
    color: #fff;
}

/*================= 03.2 - Add Client ===============*/
.add_client .card-header {
    border-bottom: none;
    padding-bottom: 0;
}

/*.add_client .card-header h5 {*/
/*    color: var(--royalBlueDark);*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 30px;*/
/*    line-height: 47px;*/
/*    text-transform: capitalize;*/
/*}*/
/*.btn_add_client {*/
/*    color: #FFFFFF;*/
/*    background: var(--cadmiumOrange);*/
/*    border: 1px solid var(--cadmiumOrange);*/
/*    border-radius: 22px;*/
/*    padding: 10px 30px;*/
/*}*/

/*.btn_back_client {*/
/*    font-weight: 500;*/
/*    font-size: 16px;*/
/*    line-height: 17px;*/
/*    color: #FFFFFF;*/
/*    background: var(--royalBlueDark);*/
/*    border-radius: 22px;*/
/*    border: 1px solid var(--royalBlueDark);*/
/*    padding: 10px 30px;*/
/*}*/

/*.add_client label {*/
/*    font-weight: 500;*/
/*    font-size: 14px;*/
/*    line-height: 19px;*/
/*    color: var(--neutralNavy);*/
/*}*/

.add_client input:focus {
    border-bottom: 1px solid var(--burntOrange);
    background-image: none;
}

.wrapper_margin {
    margin-left: 15px;
    margin-right: 15px;
}

div.sector_box {
    height: 100%;
    background: #535763;
    border-radius: 15px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_text {
    color: var(--royalBlueDark);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-transform: capitalize;
}

.custom_bottom_sector_p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #535763;
}

div.sector_box_active {
    height: 100%;
    background: var(--blueJeans);
    border-radius: 15px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

p.sector_label {
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF !important;
    margin-bottom: 0 !important;
    margin-top: 10px;
}

.sector_img_label_wrapper {
    padding: 8px;
}

.custom_row_flex {
    display: flex;
    flex-wrap: wrap;
}

.eye_position {
    position: absolute;
    z-index: 2;
    color: #9D9D9D;
    right: 13px;
    top: 45px;
    cursor: pointer;
}

.rdt_TableHead {
    border-radius: 50%;
}

/*================================ 03.2 - Edit Client ================================*/
.edit_profile .custom_heading_text {
    font-weight: 400;
    font-size: 30px;
    line-height: 47px;
    text-transform: lowercase;
    color: var(--royalBlueDark);
}

.edit_profile .custom_bg_card {
    background-color: var(--dashBoardBg);
}

.edit_profile .card.parent_card {
    border-radius: 15px;
}

.edit_profile .card .card-header {
    border: none;
}

.edit_profile .card.edit_profile_card_left {
    border-radius: 15px;
}

.edit_profile .user_cover_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    border-radius: 15px 15px 0 0 !important;
}

.edit_profile .user-card .change-profile .profile-dp {
    width: 150px;
    height: 150px;
}

.edit_profile .user-card .user-about-block {
    margin-top: -80px;
}

.edit_profile .user-card .cover-img-block .change-cover {
    position: absolute;
    top: 10px;
    right: 10px;
    left: unset !important;
    z-index: 5;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

/*.edit_profile .user-card .cover-img-block .change-cover:hover {*/
/*    opacity: 1;*/
/*}*/

.edit_profile .avatar_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    margin-bottom: 20px;
}

.edit_profile .custom_row_flex {
    display: flex;
    flex-wrap: wrap;
}

.edit_profile .custom_card_height {
    height: 100%;
}

/*.edit_profile .input_fields_label {*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    line-height: 22px;*/
/*    color: var(--royalBlueDark);*/
/*}*/

.edit_profile .sector_interests .bg-interest {
    background: var(--emerald);
    color: #ffffff;
    border-radius: 29px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    font-style: normal;
    cursor: pointer;
}

.edit_profile .sector_interests .bg-inactive {
    outline: 1px solid;
    color: #fff;
    border-radius: 29px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    font-style: normal;
    cursor: pointer;
    background: var(--navInactive);
}

.edit_profile .sector_interests .bg {
    background: var(--navInactive);
    color: #ffffff;
    border-radius: 29px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    font-style: normal;
    cursor: pointer;
}

/*.edit_profile .save_btn {*/
/*    border-radius: 33px;*/
/*    padding: 12px 30px;*/
/*    color: #fff;*/
/*    background-color: var(--cadmiumOrange);*/
/*    border: none;*/
/*    font-weight: 500;*/
/*    font-size: 16px;*/
/*    float: right;*/
/*}*/

.edit_profile input.form-control[type=radio] {
    height: 14px;
    width: 10%;
}

.edit_profile input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--royalBlueDark);
    font-style: normal;
}

.edit_profile textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--royalBlueDark);
    font-style: normal;
}

.edit_profile input[type="radio"]+label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-style: normal;
}

.edit_profile select option {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--royalBlueDark);
    font-style: normal;
}

/*.edit_profile .margin_bottom {*/
/*    margin-bottom: 30px;*/
/*}*/

.edit_profile .overlay span {
    background: none !important;
    border: none !important;
    width: 35px !important;
    height: 35px !important;
    left: 70px !important;
    transform: translate(-50%, -20%);
    padding: 2px !important;
    border-radius: 50%;
    font-size: 19px;
    background: var(--blueJeans) !important;
    opacity: 0.90;
}
.edit_profile .overlay img {
    box-shadow: none;
}
.edit_profile .user-card .cover-img-block .change-cover .dropdown-toggle {
    background: var(--blueJeans);
    opacity: .90;
    width: 35px;
    height: 35px;
    text-decoration: none;
}
.edit_profile .css-1s2u09g-control:hover {
    border-color: rgba(0, 0, 0, 60%);
}

.edit_profile .profile-avatar-wrapper {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
}

.edit_profile .form_control_country .css-1s2u09g-control {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: unset;
}

.edit_profile .profile-avatar {
    width: 25%;
    padding: 10px;
}

/*================= 04 - Campaigns ===============*/

/*.campaign_stepper_heading {*/
/*    font-family: 'Audiowide';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 30px;*/
/*    line-height: 38px;*/
/*    text-align: center;*/
/*    text-transform: capitalize;*/
/*    color: var(--royalBlueDark);*/
/*}*/

.select-client {
    min-height: calc(100vh - 480px);
}

.select-client .client--box {
    border-radius: 15px;
    padding: 10px 20px;
    height: 30vh;
}

.pick-template .choose--temp--card {
    border-radius: 15px;
    overflow: hidden;
}

.pick-template .cover-img-block img {
    width: 100% !important;
    border-radius: 15px 15px 0px 0px;
}

.pick-template .choose--temp--card .card-footer {
    border-radius: 0px 0px 15px 15px;
}

.content-design .navbar-light .navbar-toggler {
    border: none;
}

.content-design h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    /*padding: 10px 15px;*/
}

.content-design .integrate-links {
    background: var(--blueJeans);
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    font-style: normal;
    padding: 8px 30px;
}

.content-design p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    /*text-align: center;*/
    /*color: var(--royalBlueDark);*/
    margin-bottom: 0;
}

.content-design .review {
    background: var(--cadmiumOrange);
    border-radius: 22px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 30px;
}

.content-design a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: var(--navInactive);
}

.campaign-details-modal .quill {
    max-height: 425px;
    overflow-y: scroll;
}

.template-header {
    /*background: #c4c4c4;*/
    height: auto;
    padding-bottom: 2em;
}

.template-header .logo {
    padding: 5px 15px;
    text-align: center;
    text-transform: uppercase;
}

.template-header .logo img {
    max-width: 250px;
    max-height: 120px;
}

.template-skeleton-border {
    border: 2px dashed #5c5c5c;
}

.skeleton-border-bottom {
    border-bottom: 2px dashed #5c5c5c;
}

.header_bg_position {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.template-skeleton {
    /*width: 943px;*/
    height: 100%;
    background: white;
    /*border: 0.25px solid #C4C4C4;*/
    box-sizing: border-box;
    /*margin-top: 15px;*/
    /*padding: 20px;*/
}

/*.template-skeleton .add-icon-logo {*/
/*    position: relative;*/
/*    top: -21px;*/
/*    right: -6px;*/
/*    float: right;*/
/*    font-size: 20px;*/
/*    color: var(--burntOrange);*/
/*}*/
.btn.btn-campaign-edit {
    padding: 7px;
}

.btn-campaign-edit {
    font-size: 14px;
    border: 2px solid white;
    background: var(--blueJeans);
    padding: 7px;
    display: flex;
    color: white;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    justify-content: center;
}

.btn-campaign-edit:hover {
    background: var(--royalBlueDark);
    color: white;
}

.template-skeleton .header-text {
    /*text-align: center;*/
    word-break: break-word;
    padding: 5px;
}

.template-content-area {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}


.pricing_plan .custom_card_body ul li {
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: bold;
    border-bottom: 1px solid #DBDAD9;
}

.pricing_plan .plan_features ul li {
    background: var(--royalBlueDark);
    margin-bottom: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px 0px 0px 15px;
    color: #fff;
    font-weight: bold;
}

/*================= Generic Campaign ===============*/
/*================= Video Campaign ===============*/
.video-box-wrap {
    margin: 25px 0;
    height: 300px;
}

.reward_edit_icon__bg {
    position: relative;
    top: -35px;
    right: -20px;
    float: right;
    font-size: 20px;
}

/*================= Hashtag Campaign ===============*/
/*================= Leader Board Campaign ===============*/
/*.leaderboard_header-palette-icon {*/
/*    position: absolute;*/
/*    top: 0px;*/
/*    right: 10px;*/
/*    float: right;*/
/*    font-size: 20px;*/
/*}*/
/*================= Music Clip Campaign ===============*/

/*================= Upload file Campaign ===============*/
.upload-file-right-bg {
    position: absolute;
    width: 50%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    right: 0;
    background-repeat: no-repeat !important;
}

.upload-file-left-bg {
    position: absolute;
    width: 50%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    left: 0;
}

.progress_bar {
    width: 320px;
    height: 147px;
    background: rgba(1, 1, 1, 0.68);
    border-radius: 15px;
    /*position: absolute;
    top: 419px;
    left: 115px;*/
}

.progress_bar_header p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 15px;
}

.progress_bar_header p span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    float: right;
}

.progress_bar_content .progress {
    height: 1.5rem;
    /*margin-bottom: 5px;*/
}


.header-text p {
    word-break: break-word;
    padding: 10px 15px 10px 0;
}
.reward-text p {
    word-break: break-word;
    padding: 0 15px 10px 0;
}

.template-skeleton .header-text h4 {
    padding: 15px 0;
}

/*.template-skeleton .header-text h5 {
    padding: 5px;
}*/

.fa-circle-plus,
.fa-circle-minus,
.fa-edit {
    color: var(--blueJeans);
}

.add-icon-reward {
    position: relative;
    top: -31px;
    right: -26px;
    float: right;
    font-size: 20px;
}

.add-icon-reward-img {
    position: absolute;
    top: -14px;
    right: 6px;
    float: right;
    font-size: 20px;
}

/*.reward-wrap {*/
/*    padding: 45px 30px;*/
/*    margin: 0 25px 25px 25px;*/
/*}*/
.template-content-area .reward-text {
    overflow-wrap: break-word;
    overflow: hidden;
    /*padding: 15px;*/
    height: auto;
    /*max-height: 200px;*/
}

/*.template-content-area .reward-social {
    padding-left: 15px;
}*/
.header-palette-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.editor-palette-icon {
    position: relative;
    top: -15px;
    right: -10px;
    float: right;
    font-size: 20px;
}

.signup-palette-icon {
    position: relative;
    top: -15px;
    right: 10px;
    font-size: 20px;
}

.signin-palette-icon {
    /*wiil remove*/
    position: relative;
    top: 5px;
    right: 0px;
    float: right;
    font-size: 20px;
}

.sign-up-palette-icon {
    position: relative;
    top: -25px;
    right: -15px;
    float: right;
    font-size: 20px;
}

.content-palette-icon {
    position: relative;
    top: -18px;
    right: 5px;
    float: right;
    font-size: 20px;
}

.footer-palette-icon {
    position: relative;
    top: 0px;
    right: 5px;
    float: right;
    font-size: 20px;
}

.reward-social h3 {
    font-size: 18px;
    margin-top: 15px;
}

.reward-social a:first-child {
    padding-left: 0;
}

.template-footer {
    background: #c4c4c4;
    /*height: 74px;*/
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.review_footer_text {
    padding: 15px 50px;
}

/*.campaign-modal {
    border: 1px solid #000;
    padding: 5px 10px !important;
    border-radius: 20px;
}*/
.campaign-subdomain-input-right {
    border: 1px solid #000;
    padding: 5px 10px !important;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.campaign-subdomain-input-left {
    border: 1px solid #000;
    padding: 5px 10px !important;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    background: #FFFFFF;
}

.campaign-preview h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: var(--neutralNavy);
}

.campaign-preview h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: var(--royalBlueDark);
}

.campaign-preview p {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.campaign-preview a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline !important;
    color: var(--blueJeans) !important;
}

.preview {
    /*border: 1px solid #ccc;*/
    /*!*width: 456.62px;*!*/
    /*height: auto;*/
}

.preview img {
    border-radius: 15px;
}

.preview a {
    position: absolute;
    top: -10px;
    right: 6px;
}

.active-campaign-text {
    background: #024799;
    border-radius: 0px 0px 5px 5px;
    padding: 5px 10px;
    color: #FFFFFF;
}

.btn_lb {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    padding: 10px 30px;
    margin-right: 10px;
}

.leaderboard_list_box {
    /* background: #FFFFFF;*/
    border: 1px solid #000000;
    position: relative;
    top: -169px;
    border-radius: 31px;
}

.user-Messages-card {
    border-radius: 31px !important;
}

.leaderboard_list_box h5 {
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
    text-align: center;
}

.leaderboard_list_items {
    padding: 5px 0px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 61px;
}

.leaderboard_signup {
    border-radius: 22px;
    padding: 5px 25px;
}

.leaderboard_signin {
    color: #000000;
    font-size: 18px;
    text-decoration: underline;
    margin-left: 5px;
}

.trending_image_box {
    width: 400px;
    height: auto;
    padding: 5px;
    background: white;
}

.trending_image_box h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    padding: 15px 0;
    margin-bottom: 0;
}

.trending_image_box .img_thumb {
    width: 130px;
    height: 130px;
    padding: 2px;

}

.trending_image_box .img_thumb img {
    width: 126px;
    height: 126px;
    object-fit: cover;
    background: #A4A4A4;
}

.trending_image_box .img_thumb span {
    left: 5px;
    bottom: 5px;
}

.trending_image_box .see-more {
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
    color: #000000;
}

.hash_tag_box {
    position: absolute;
    bottom: 10px;
    left: 140px;
}

/*================= 4.01 - Campaign Hub ===============*/
.campaign_hub .rdt_TableCol {
    text-transform: uppercase;
}

.campaign_hub .rdt_TableCell:last-child {
    justify-content: center;
}

.status_dropdown_active {
    padding: 5px;
    height: auto;
    border-radius: 15px;
    border: none;
    background: var(--emerald);
    color: #FFFFFF;
    font-size: 12px;
}

.status_dropdown_pending {
    padding: 5px;
    height: auto;
    border-radius: 15px;
    border: none;
    background: var(--goldMetallic);
    color: #FFFFFF;
    font-size: 12px;
}

.status_dropdown_inactive {
    padding: 5px;
    height: auto;
    border-radius: 15px;
    border: none;
    background: var(--deleteColor);
    color: #FFFFFF;
    font-size: 12px;
}

.expire_check {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    justify-content: center;
    display: flex;
}
.select_chevron_down {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}
.chevron-icon .fa {
    position: absolute;
    top: 18px !important;
    right: 30px !important;
    color: #FFFFFF;
    pointer-events: none;
}
/*================= 05 - Admin ===============*/


/*================= 5.01 - Add Admin ===============*/
.add_admin .card-header {
    border-bottom: none;
    padding-bottom: 0;
}

/*.add_admin .card-header h5 {*/
/*    font-weight: 700;*/
/*    font-size: 22px;*/
/*    line-height: 27px;*/
/*    color: var(--neutralNavy);*/
/*}*/

/*.add_admin label {*/
/*    font-weight: 500;*/
/*    font-size: 14px;*/
/*    line-height: 19px;*/
/*    color: var(--neutralNavy);*/
/*}*/
/*.add_admin input {*/
/*    border-bottom: 1px solid #000000;*/
/*}*/
/*.add_admin input:focus {*/
/*    border-bottom: 1px solid var(--burntOrange);*/
/*    background-image: none;*/
/*}*/

.add_admin .css-yk16xz-control {
    border-color: var(--burntOrange) !important;
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    border-radius: 0 !important;
}

.add_admin .css-1pahdxg-control:hover {
    border-color: var(--burntOrange) !important;
    border: none;
    border-bottom: 1px solid var(--burntOrange);
    border-radius: 0;
}

.add_admin .css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
}

.add_admin .css-tlfecz-indicatorContainer {
    color: var(--blueJeans);
}

/*================= 06 - Notifications ===============*/
/*================= 07 - Roles & Permissions ===============*/


/*=============================================*/

.bottom-menu {
    position: absolute;
    bottom: 20px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1em;
    margin-bottom: 0px;
}

.active-campaigns {
    /*width: 114px;
    height: 113px;
    background: #EBEBEB;*/
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*border-radius: 16px;*/
    margin-top: 15px;
}

/* Overview CSS */
.overview .total--campaign--record {
    border-radius: 5px;
}

.overview .latest--activity--card {
    border-radius: 15px;
}

.overview .view--all {
    margin-top: 15px;
}

.overview .view--all a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.image-selector,
.color-selector {
    background: var(--cadmiumOrange);
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100px;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
}

/*=================  - Quill css ===============*/

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
}

.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
    font-family: "Comic Sans MS", cursive, sans-serif;
}

.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
    font-family: "Courier New";
}

.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
    font-family: Georgia, serif;
}

.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif;
}

.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.ql-font-poppins,
.ql-font span[data-value="poppins"]::before {
    font-family: "Poppins", sans-serif;
}

.ql-font-roboto,
.ql-font span[data-value="roboto"]::before {
    font-family: "Roboto", sans-serif;
}

.ql-font-montserrat,
.ql-font span[data-value="montserrat"]::before {
    font-family: "Montserrat", sans-serif;
}

.ql-font-macondo,
.ql-font span[data-value="macondo"]::before {
    font-family: 'Macondo', cursive;
}

.ql-font-oleo,
.ql-font span[data-value="oleo"]::before {
    font-family: 'Oleo Script Swash Caps', cursive;
}

/* Set content for sizes */
.ql-size-font-10 {
    font-size: 10px !important;
}

.ql-size-font-12 {
    font-size: 12px !important;
}

.ql-size-font-14 {
    font-size: 14px !important;
}

.ql-size-font-16 {
    font-size: 16px !important;
}

.ql-size-font-18 {
    font-size: 18px !important;
}

.ql-size-font-20 {
    font-size: 20px !important;
}

.ql-size-font-22 {
    font-size: 22px !important;
}

.ql-size-font-24 {
    font-size: 24px !important;
}

.ql-size-font-30 {
    font-size: 30px !important;
}

.ql-size-font-36 {
    font-size: 36px !important;
}

.ql-size-font-40 {
    font-size: 40px !important;
}

/* Set content for position */
.ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}

.Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
}

.Demo__some-network__share-button {
    cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
}

.Demo__some-network__custom-icon {
    width: 32px;
    height: 32px;
}


.interests .bg {
    background: #D36011;
    color: #FFFFFF;
    padding: 8px 20px;
    margin-bottom: 10px;
}

.interests .bg-interest {
    background: var(--burntOrange);
    color: #ffffff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.interests .bg-inactive {
    outline: 1px solid;
    outline-color: var(--burntOrange);
    color: var(--burntOrange);
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.campaign-search input {
    background: #ECF0F5;
    border: 1px solid var(--neutralNavy);
    box-sizing: border-box;
    border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.user-card-2.shape-right .cover-img-block {
    clip-path: none;
}

/* Client Profile */
.cover-img-block img {
    width: 100% !important;
}

.connections-follow h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--neutralNavy);
}

.connections-follow h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 159.52%;
    color: #D31111;
    cursor: pointer;
}

.connections-follow p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 159.52%;
    color: #535763;
}

.connections-suggest h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 159.52%;
    color: var(--neutralNavy);
}

.connections-suggest a {
    color: var(--neutralNavy);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

/*.roles-permissions .card-title {*/
/*    font-weight: 700 !important;*/
/*    font-size: 22px !important;*/
/*    line-height: 27px;*/
/*    color: var(--neutralNavy) !important;*/
/*}*/
.roles-permissions .btn_add_role {
    background: var(--neutralNavy);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 10px 30px;
}

.roles-permissions .theader-style {
    background: #535763;
    color: #fff;
    text-transform: uppercase;
}

.roles-permissions .nav-pills .left-tab-header .nav-link {
    background: #535763;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    border-radius: 0;
    height: 40px;
    display: flex;
    align-items: center;
}

.roles-permissions .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--burntOrange);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    border-radius: 0;
}

.roles-permissions .nav-pills .nav-link {
    background: #ECF0F5;
    border-bottom: 1px solid #72777A;
    border-radius: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #000;
    line-height: 16px;
}

.roles-permissions .action-label {
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.roles-permissions .switch input[type=checkbox]+.cr {
    top: 5px;
}

.roles-permissions .switch input[type=checkbox]:checked+.cr:before {
    background: #E3A981;
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.roles-permissions .switch input[type=checkbox]+.cr:after {
    background: var(--burntOrange);
}

.roles-permissions .switch input[type=checkbox]:checked+.cr .disable-allow:before {
    background: #535763;
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.custom-switch label {
    color: var(--greenPantone);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.roles-permissions .form-group label.not-allow {
    color: #535763;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.roles-permissions .table-card tr {
    background: #ECF0F5;
}

/*.add-role-modal .modal-header {*/
/*    background: var(--neutralNavy);*/
/*}*/

.add-role-modal .modal-header h5 {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
}

.add-role-modal label {
    font-style: normal;
    /*font-weight: 500;*/
    font-size: 16px;
    /*color: var(--neutralNavy);*/
}

.add-role-modal label span {
    color: var(--burntOrange);
}

.add-role-modal input {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    border-bottom: 1px solid #000000;
}

.add-role-modal input:focus {
    border-bottom: 1px solid var(--burntOrange);
    background-image: none;
}

/*.add-role-modal .add-role-btn {*/
/*    border-radius: 5px;*/
/*    border: 1px solid var(--burntOrange);*/
/*}*/

.add-role-modal .modal-footer {
    border-top: none;
}

.btn-stepper-next {
    background: var(--cadmiumOrange);
    color: #FFFFFF;
    border-radius: 22px;
    padding: 8px 30px;
    border: 1px solid var(--cadmiumOrange);
    cursor: pointer;
}

.btn-stepper-next:hover {
    background: #ff8c00;
    color: #FFFFFF;
    border: 1px solid #ff8c00;
}

.btn-stepper-prev {
    background: var(--navInactive);
    color: #FFFFFF;
    border-radius: 22px;
    padding: 8px 30px;
    border: 1px solid var(--navInactive);
    cursor: pointer;
}

.btn-stepper-prev:hover {
    background: #42464f;
    color: #FFFFFF;
    border: 1px solid #42464f;
}

.css-yk16xz-control {
    border-color: var(--blueJeans) !important;
}

.css-1pahdxg-control {
    box-shadow: none !important;
}

.css-1pahdxg-control:hover {
    border-color: var(--blueJeans) !important;
}


/* Campaign Modal */
/* .campaign-details label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--royalBlueDark);
} */

.campaign-details input {
    border-bottom: 1px solid #000000;
}

.campaign-details input:focus {
    border-bottom: 1px solid var(--burntOrange);
    background-image: none;
}

.campaign-details span {
    color: var(--cadmiumOrange);
}

.campaign-details .react-datepicker-popper {
    z-index: 2;
}

.campaign-details .form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
}

.modal-content {
    border-radius: 17px !important;
}

.bg-modal-header {
    background: var(--royalBlueDark);
    height: 50px;
    padding: 13px 20px;
    border-radius: 15px 15px 0px 0px;
}

.bg-modal-header h5 {
    color: #FFFFFF;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
}

.btn-footer-modal-next {
    background: var(--cadmiumOrange);
    border: 1px solid var(--cadmiumOrange);
    border-radius: 19px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 30px;
}

.btn-footer-modal-next:hover {
    background: #ff8c00;
    color: #FFFFFF;
    border: 1px solid #ff8c00;
}

.btn-footer-modal-prev {
    background: var(--royalBlueDark);
    border: 1px solid var(--royalBlueDark);
    border-radius: 19px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 30px;
}

.btn-footer-modal-prev:hover {}

.field-domain {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
}

.campaign-details .calendar-icon {
    float: right;
    margin-right: 5px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
}

#campaign-domain.form-control.is-valid {
    padding-right: 157px !important;
    background-image: none;
}

/* .goals-modal label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--royalBlueDark);
} */

.goals-modal input {
    border-bottom: 1px solid #000000;
    color: #000000;
}

.goals-modal input:focus {
    border-bottom: 1px solid var(--burntOrange);
    background-image: none;
}

.modal-header .close {
    color: #fff;
}

.integrate-modal h6 {
    font-weight: 600;
    font-size: 14px;
    color: var(--neutralNavy);
    margin-bottom: 10px;
}

.integration-social-messages {
    background: #ECF0F5;
    border-bottom: none;
    border-radius: 15px;
    padding: 5px 24px 5px 5px !important;
    /*height: 50px !important;*/
    border: 1px solid #ECF0F5;
}

.integration-social-messages:focus {
    background: #ECF0F5;
    border: 1px solid var(--blueJeans);
    border-radius: 15px;
}

.integration-social-analytics {
    border-bottom-color: #000000;
    padding: 12px 10px !important;
}

.integration-social-analytics:focus {
    border-color: var(--burntOrange);
    background-image: none;
}

.info-note {
    float: right;
    margin-right: 7px;
    margin-top: -45px;
    position: relative;
    z-index: 2;
}

.info-note-textarea {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
}

.image-edit-btn i {
    background: var(--blueJeans);
    border-radius: 50%;
    padding: 8px;
    color: #fff;
}

.image-edit-btn i:hover {
    background: var(--neutralNavy);
}

.add-image-container {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    height: 180px;
    width: 180px;
}

.add-image-container .image-edit-btn {
    position: absolute;
    bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 5px;
    font-size: 22px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
}

.upload_profile_note {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #024799;
    margin-top: 20px;
    text-align: center;
}

.upload_profile_note span {
    color: var(--burntOrange);
}

.image-container {
    border-radius: 50%;
    /*padding-top: 40px;*/
    width: 180px;
    height: 180px;
}

.btn-edit-preview {
    background: var(--blueJeans);
    border-radius: 19px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: 1px solid var(--blueJeans);
    padding: 8px 30px;
}

.btn-done-preview {
    background: var(--cadmiumOrange);
    border-radius: 19px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: 1px solid var(--cadmiumOrange);
    padding: 8px 30px;
}

.upload_sign_in {
    margin-top: 45px;
    padding-right: 30px;
}

.profile-dp .overlay span {
    left: 0;
}

.whatsapp_link {
    stroke: green !important;
}

.facebook_link {
    stroke: blue !important;
}

.email_link {
    stroke: purple !important;
}

.twitter_link {
    stroke: lightblue !important;
}

.custom_tree_node {
    position: relative;
    /* z-index: 999; */
}

/* .custom_tree_node .node_data {
    display: none;
    background: lightgrey;
    padding: 10px;
    border-radius: 5px;
}

.custom_tree_node:hover .node_data {
    display: block;
} */

/* Terms and Condition */
.static-content h2, .static-content h3 {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: var(--royalBlueDark);
    text-align: center;
}
.static-content h2 {
    margin-top: 30px;
}
.static-content h4 {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: var(--royalBlueDark);
}
.static-content h5 {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--royalBlueDark);
}
.content-item {
    font-family: 'Audiowide';
    font-weight: 400;
    font-size: 16px;
    color: var(--royalBlueDark);
}
.content-roman {
    list-style: lower-roman !important;
}
/*.static-content ol {
    counter-reset: item
}
.static-content  li {
    display: block
}
.static-content  li:before {
    content: counters(item, ".") " ";
    counter-increment: item
}*/
.static-content ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

.static-content li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

.static-content li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    color: var(--royalBlueDark);
    font-weight: 600;
}

.static-content li li {
    margin: 0;
}

.static-content li li:before {
    content: counters(item, ".") " ";
    font-weight: 400;
}
.static-content .table td, .static-content .table th {
    white-space: normal;
}

/*=================  - Subdomain user profile ===============*/
.profile-div {
    margin: 80px 0 50px !important;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 !important;
}
.user-profile-toggle button:after {
    display: none;
}
.user-profile-toggle .btn-link:hover {
    color: #FFFFFF;
}
.user-profile-toggle .dropdown-item:hover, .dropdown-item:focus {
    background-color: transparent !important;
}
.user-profile-toggle span svg {
    display: none;
}
.modal-90w {
    width: 90% !important;
    max-width: none!important;
    height: 90vh;
}
.dropdown-img {
    border-radius: 50% !important;
    border: 1px solid grey;
    cursor: pointer;
}
/*=================  - Media Queries ===============*/
@media (max-width: 1024px) {
    .chevron-icon .fa {
        top: 20px !important;
        right: 25px !important;
        font-size: 9px;
    }
}

@media (max-width: 991px) {
    .pcoded-header > .collapse:not(.show), .pcoded-header .container > .collapse:not(.show) {
        background: var(--blueJeans) !important;
    }
}
@media (max-width: 768px) {
    .upload-file-left-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        left: 0;
    }
}
.ant-notification {
    z-index: 1073;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.copy-link {
    background: var(--majorelleBlue);
    border-radius: 50%;
    padding: 0.4rem
}

.maintenance-heading {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    text-align: center;
    color: #5E48E3;
}
.maintenance-text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #23225C;
}
.btn-maintenance {
    padding: 12px 50px;
    border-radius: 49px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF !important;
}
.btn-maintenance-home {
    background: #23225C;
}
.btn-maintenance-contact {
    background: #5E48E3;
}

/* Floating Social Media Bar Style Starts Here */

.fl-fl {
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 4px;
    width: 179px;
    position: fixed;
    right: -140px;
    z-index: 1000;
    font: normal normal 10px Arial;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.fl-fl svg {
    width: 30px;
}

/*.fa {*/
/*    font-size: 20px;*/
/*    color: #fff;*/
/*    padding: 10px 0;*/
/*    width: 40px;*/
/*    margin-left: 8px;*/
/*}*/

.fl-fl:hover {
    right: -125px;
}
.fl-fl span {
    margin-left: 5px;
    color: #000000;
}
/*.fl-fl a {
    color: #000 !important;
    text-decoration: none;
    text-align: center;
    line-height: 43px!important;
    vertical-align: top!important;
}*/

.float-fb {
    top: 160px;
}

.float-twitter {
    top: 200px;
}

.float-whatsapp {
    top: 240px;
}

.float-email {
    top: 280px;
}

.float-linkedin {
    top: 320px;
}

.float-copylink {
    top: 368px;
}
.custom-spinner {
    position: absolute;
    left: 15px;
    top: 3px;
}
/* Floating Social Media Bar Style Ends Here */
